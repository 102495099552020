import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useCurrentDomain() {
  const location = useLocation();
  const domain = window.location.hostname;

  return useMemo(() => {
    let isMarketplace = domain.includes('market.crea.la') || domain === 'localhost';
    let isCreatorDomain = !isMarketplace;

    let creatorUsername = null;

    if (isMarketplace && location.pathname.startsWith('/creator/')) {
      const parts = location.pathname.split('/');
      creatorUsername = parts[parts.length - 1];
    } else if (isCreatorDomain && !location.pathname.startsWith('/product/')) {
      const parts = domain.split('.');
      creatorUsername = parts[0] === 'www' ? parts[1] : parts[0];
    }

    // For testing:

    // CreatorDomain - Main Page
    // isMarketplace = false;
    // isCreatorDomain = true;
    // creatorUsername = 'luisitoguanes';
    // CreatorDomain - Product
    // isMarketplace = false;
    // isCreatorDomain = true;

    return {
      isMarketplace,
      isCreatorDomain,
      creatorUsername,
      domain
    };
  }, [location, domain]);
}
