import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CreatorPage.scss';

// Data Services
import ProductDataService from '../../services/ProductsService';
import TagsDataService from '../../services/TagsService';

// Components
import NotFoundPage from '../notFoundPage/NotFoundPage';
import Section from '../Sections';
import Header from '../Header';
import ProductBox from '../ProductBox';
import Tags from '../Tags';
import SearchBar from '../SearchBar';
import CreatorFooter from '../CreatorFooter';

// Assets
import defaultAccountImage from '../../assets/avatar.png';
import linkedin from '../../assets/creatorPage/linkedin.svg';
import facebook from '../../assets/creatorPage/facebook.svg';
import instagram from '../../assets/creatorPage/instagram.svg';
import twitter from '../../assets/creatorPage/twitter.svg';
import tiktok from '../../assets/creatorPage/tiktok.svg';
import { useCurrentDomain } from '../../hooks/useCurrentDomain';
import { ReactComponent as MadeInCreala } from '../../assets/MadeInCreala.svg';
import { useCreator } from '../../hooks/useCreator';

const chunkArray = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export default function CreatorPage({ creatorUsername }) {
  const pageSize = 8;
  const [productsList, setProductsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);

  const { creator, loading, error: creatorError } = useCreator(creatorUsername);

  useEffect(() => {
    const getTags = () => {
      let params = {};
      params['creatorUsername'] = creatorUsername;

      TagsDataService.getAll(params)
        .then((response) => {
          setTags(Object.values(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getTags();
  }, []);

  const filterProducts = (updatedSelectedTags) => {
    setPage(0);
    setProductsList([]);
    setSelectedTags(updatedSelectedTags);
  };

  const searchProducts = (event) => {
    setPage(0);
    setProductsList([]);
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    let params = {};
    params['creatorUsername'] = creatorUsername;

    if (searchQuery) {
      params['query'] = searchQuery;
    }

    if (selectedTags) {
      params['tags'] = selectedTags;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    ProductDataService.getAll(params)
      .then((response) => {
        const { products } = response.data;
        setProductsList((productsList) => chunkArray([...productsList, ...products], 2));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [page, selectedTags, searchQuery]);

  const { isCreatorDomain } = useCurrentDomain();

  if (creatorError) {
    return <NotFoundPage />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="creator-page-wrapper">
      {creator && (
        <div className="creator-page-content">
          <Section type="creator-page">
            <Header creator={creator} showPic={false} />
            <div className="creator-page">
              <div className="creator-page_info">
                <img
                  src={creator.profilePicture?.length > 0 ? creator.profilePicture : defaultAccountImage}
                  alt={creator.firstName}
                  className="creatorPicture"
                />
                <div className="creator-page_bio">
                  <p>{creator.bio ? creator.bio : ''}</p>
                  <div className="creator-page_social">
                    {creator.socialMedia?.linkedin && (
                      <a className="link" href={creator.socialMedia.linkedin} target="_blank" rel="noreferrer">
                        <div className="social">
                          <img src={linkedin} width={20} height={20} />
                          <p>LinkedIn</p>
                        </div>
                      </a>
                    )}
                    {creator.socialMedia?.facebook && (
                      <a className="link" href={creator.socialMedia.facebook} target="_blank" rel="noreferrer">
                        <div className="social">
                          <img src={facebook} width={20} height={20} />
                          <p>Facebook</p>
                        </div>
                      </a>
                    )}
                    {creator.socialMedia?.instagram && (
                      <a className="link" href={creator.socialMedia.instagram} target="_blank" rel="noreferrer">
                        <div className="social">
                          <img src={instagram} width={20} height={20} />
                          <p>Instagram</p>
                        </div>
                      </a>
                    )}
                    {creator.socialMedia?.twitter && (
                      <a className="link" href={creator.socialMedia.twitter} target="_blank" rel="noreferrer">
                        <div className="social">
                          <img src={twitter} width={20} height={20} />
                          <p>Twitter / X</p>
                        </div>
                      </a>
                    )}
                    {creator.socialMedia?.tiktok && (
                      <a className="link" href={creator.socialMedia.tiktok} target="_blank" rel="noreferrer">
                        <div className="social">
                          <img src={tiktok} width={20} height={20} />
                          <p>TikTok</p>
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="creator-page_products">
                <SearchBar setSearchQuery={searchProducts} />
                <div className="tags">
                  <Tags size="lg" selectionType="multi" tags={tags} onChangeSelection={filterProducts} />
                </div>
                <div className="creator-page_products_list">
                  {productsList &&
                    productsList.map((productsChunk, index) => (
                      <div key={index} className="creator-page_products_list_row">
                        {productsChunk.map((product) => (
                          <Link
                            to={`/product/${product.slug}`}
                            key={product.id}
                            className="creator-page_products-link"
                            style={{ textDecoration: 'none', display: 'flex' }}
                          >
                            <ProductBox product={product} hideCreator={true} />
                          </Link>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              {isCreatorDomain && <MadeInCreala className="made-in-creala" />}
            </div>
          </Section>
        </div>
      )}
      {isCreatorDomain ? <CreatorFooter creator={creator} /> : null}
    </div>
  );
}

CreatorPage.propTypes = {
  creatorUsername: PropTypes.string.isRequired
};
