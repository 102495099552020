import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AllLinks from './services/AllLinks';
import { useCurrentDomain } from './hooks/useCurrentDomain';

function App() {
  const { isMarketplace } = useCurrentDomain();

  return (
    <div className="App">
      {isMarketplace && <Navbar />}
      <AllLinks />
      {isMarketplace && <Footer />}
    </div>
  );
}

export default App;
