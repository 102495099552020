import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCurrentDomain } from '../../hooks/useCurrentDomain';
import CreatorFooter from '../CreatorFooter';
import { useCreator } from '../../hooks/useCreator';

import ProductDataService from '../../services/ProductsService';
import { useParams } from 'react-router-dom';
import Tags from '../Tags';
import './ProductPage.scss';
import Button from '../Buttons';
import Breadcrumb from '../Breadcrumb';
import Slider from '../Slider';
import NotFoundPage from '../notFoundPage/NotFoundPage';
import Header from '../Header';

import Section from '../../components/Sections';
import { calcFrequency } from '../../utils';

// Assets
import defaultProductImage from '../../assets/defaultProduct.png';
import defaultAccountImage from '../../assets/avatar.png';
import { ReactComponent as VerifiedUser } from '../../assets/materialSymbols/verified_user.svg';
import { ReactComponent as AssuredWorkload } from '../../assets/materialSymbols/assured_workload.svg';
import { ReactComponent as MadeInCreala } from '../../assets/MadeInCreala.svg';

function transformImageUrl(url, transformation) {
  const parts = url.split('/upload/');
  return `${parts[0]}/upload/${transformation}/${parts[1]}`;
}

export default function ProductPage() {
  const { isCreatorDomain } = useCurrentDomain();
  const [error, setError] = useState(false);
  const [product, setProduct] = useState(null);
  const [creatorUsername, setCreatorUsername] = useState(null);
  let params = useParams();
  let productId = params.productId;

  useEffect(() => {
    const getProduct = async (productId) => {
      try {
        const response = await ProductDataService.get(productId);
        let product = { ...response.data };
        product.pics.map((pic) => {
          const transformation = 'c_pad,h_450,w_800,b_rgb:f3f4f0';
          pic.imageURL = transformImageUrl(pic.imageURL, transformation);
        });
        setProduct(product);
        setCreatorUsername(product.account.userName);
        setError(false);
      } catch (e) {
        console.log(e);
        setError(true);
      }
    };
    getProduct(productId);
  }, [productId]);

  const { creator, loading: loadingCreator } = useCreator(creatorUsername);

  const payNowHandleClick = (url) => {
    window.open(url, '_blank');
  };

  if (error) {
    return <NotFoundPage />;
  }
  if (!product || !creator) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-page-wrapper">
      {product && (
        <div className="product-page-content">
          <Section type="product-page">
            {isCreatorDomain && <Header creator={creator || product.account} showPic={true} />}
            <div className="product-page">
              <div className="product-page_info">
                <div className="breadcrumb">
                  <Link to={'/'} style={{ textDecoration: 'none' }}>
                    <Breadcrumb variant="inactive">Todos los productos</Breadcrumb>
                  </Link>
                  / <Breadcrumb variant="active">{product.name}</Breadcrumb>
                </div>

                <div className="product-page_header">
                  <div className="tags left">
                    <Tags tags={product.tags} selectionType="multi" size="sm" />
                  </div>
                  <h1 className="title-md left">{product.name}</h1>
                  {!isCreatorDomain && (
                    <div className="author">
                      <Link to={'/creator/' + product.account.userName} style={{ textDecoration: 'none' }} className="author">
                        <img
                          src={product.account?.profilePicture?.length > 0 ? product.account?.profilePicture : defaultAccountImage}
                          alt={product.account?.firstName}
                          className="authorPicture"
                        />
                        <p className="authorName">{`${product.account?.firstName} ${product.account?.lastName}`}</p>
                      </Link>
                    </div>
                  )}
                </div>
                <Slider
                  images={product.pics?.length > 0 ? product.pics : [{ id: 0, imageURL: defaultProductImage }]}
                  showThumbnails={true}
                />

                <div className="left product-page-description" dangerouslySetInnerHTML={{ __html: product.description }}></div>
              </div>
              <div className="product-page_payment">
                <div className="summary">
                  <p>Precio total</p>
                  <p>{`USD ${product.price?.price / 100} ${calcFrequency(product.price?.paymentType, product.price?.recurringInterval)}`}</p>
                </div>
                {product.price.paymentLink.url && (
                  <div className="buttons">
                    <Button variant="primary" size="lg" onClick={() => payNowHandleClick(product.price.paymentLink.url)}>
                      Comprar ahora
                    </Button>
                  </div>
                )}
                <div className="disclosure">
                  <VerifiedUser />
                  <p>El acceso al producto o servicio será proveído por el creador/a luego de realizar el pago</p>
                </div>
                <div className="disclosure">
                  <AssuredWorkload />
                  <p>Este marketplace utiliza los estándares de seguridad de Stripe.</p>
                </div>
              </div>
            </div>
            {isCreatorDomain && <MadeInCreala className="made-in-creala" />}
          </Section>
        </div>
      )}
      {isCreatorDomain && !loadingCreator ? <CreatorFooter creator={creator} /> : null}
    </div>
  );
}
