import React from 'react';
import PropTypes from 'prop-types';
import './Footer.scss';
import Section from './Sections';

// Icons
import it from '../assets/icon-instagram.svg';
import tw from '../assets/icon-twitter.svg';
import tiktok from '../assets/icon-tiktok.svg';
import linkedin from '../assets/icon-linkedin.svg';
import facebook from '../assets/icon-facebook.svg';

export default function CreatorFooter({ creator }) {
  return (
    <Section color="secondary" type="full">
      <div className="footer-grid footer-grid-creator">
        <div className="footer-brand-creator">
          <h2>
            {creator.firstName} {creator.lastName}
          </h2>
          <p>Copyright © {new Date().getFullYear()}. All rights reserved.</p>
        </div>
        <div className="footer-social-creator list-group">
          <h3>Redes Sociales</h3>
          <ul className="list">
            {creator.socialMedia?.instagram && (
              <li className="list-item">
                <img src={it} alt="instagram" />
                <a className="link" href={creator.socialMedia.instagram} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
            )}
            {creator.socialMedia?.twitter && (
              <li className="list-item">
                <img src={tw} alt="twitter" />
                <a className="link" href={creator.socialMedia.twitter} target="_blank" rel="noreferrer">
                  X (Twitter)
                </a>
              </li>
            )}
            {creator.socialMedia?.tiktok && (
              <li className="list-item">
                <img src={tiktok} alt="tiktok" />
                <a className="link" href={creator.socialMedia.tiktok} target="_blank" rel="noreferrer">
                  TikTok
                </a>
              </li>
            )}
            {creator.socialMedia?.linkedin && (
              <li className="list-item">
                <img src={linkedin} alt="linkedin" />
                <a className="link" href={creator.socialMedia.linkedin} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </li>
            )}
            {creator.socialMedia?.facebook && (
              <li className="list-item">
                <img src={facebook} alt="facebook" />
                <a className="link" href={creator.socialMedia.facebook} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Section>
  );
}

CreatorFooter.propTypes = {
  creator: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    socialMedia: PropTypes.object
  }).isRequired
};
