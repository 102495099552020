import { useState, useEffect } from 'react';
import CreatorsDataService from '../services/CreatorsService';

const creatorCache = new Map();

export function useCreator(creatorUsername) {
  const [creator, setCreator] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (creatorCache.has(creatorUsername)) {
      setCreator(creatorCache.get(creatorUsername));
      setLoading(false);
    } else {
      setLoading(true);
      CreatorsDataService.get(creatorUsername)
        .then((response) => {
          const creatorData = response.data;
          creatorCache.set(creatorUsername, creatorData);
          setCreator(creatorData);
          setError(null);
        })
        .catch((e) => {
          console.error(e);
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [creatorUsername]);

  return { creator, loading, error };
}
