import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';
import defaultAccountImage from '../assets/avatar.png';

export default function Header({ creator, showPic }) {
  return (
    <div className="header">
      {showPic && (
        <img
          className="creator-pic"
          src={creator?.profilePicture?.length > 0 ? creator?.profilePicture : defaultAccountImage}
          alt={creator?.firstName}
        />
      )}
      <h1 className="creator-name">
        {creator?.firstName} {creator?.lastName}
      </h1>
    </div>
  );
}

Header.propTypes = {
  creator: PropTypes.object,
  showPic: PropTypes.bool
};
